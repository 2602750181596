<template xmlns:el-icon="http://www.w3.org/1999/xlink">
  <div class="hello">
    <div class="header">
      <div class="logo">

        <span style="float: left;margin-left: 10px">磊创伴你行一生一世网</span>
        <span style="float: right;line-height: 64px;height: 64px;display: flex;align-items: center">

<!--          <el-link style="margin-right: 18px;" @click="openHelp" :underline="false" title="敬请期待">帮助中心</el-link>-->

          <el-dropdown size="large" @command="handleCommand">

            <el-avatar style="cursor: pointer;background-color: #409EFF;font-size: large" :size="40">{{nn}}</el-avatar>

            <template #dropdown>
              <div style="padding: 0.3rem;color: gray;font-size: medium;height: 1.9rem;width: 100px;">
                <div>{{nickname}}</div>
                <div style="color:lightgray;font-size: small">{{username}}</div>
              </div>
              <el-dropdown-menu>
              <el-dropdown-item icon="User" :divided="true" command="1">个人中心</el-dropdown-item>
              <el-dropdown-item icon="Close" style="color: #F56C6C" command="2">退出登录</el-dropdown-item>
            </el-dropdown-menu>
            </template>

          </el-dropdown>

<!--          <el-button type="primary">登 录</el-button>-->
        </span>
      </div>
    </div>
    <el-container style="height: calc(100% - 64px)">
      <el-aside width="220px" style="background-color: #545c64">
        <el-menu
            :default-active="activeIndex"
            class="left-menu"
            active-text-color="#ffd04b"
            text-color="#fff"
            background-color="#545c64"
            :router=true
        >

          <el-menu-item index="/">
            <el-icon><UserFilled /></el-icon>
            <span>用户管理</span>
          </el-menu-item>

          <el-menu-item index="/customer">
            <el-icon><Basketball /></el-icon>
            <span>游戏管理</span>
          </el-menu-item>

          <el-menu-item index="/gameType">
            <el-icon><Grid /></el-icon>
            <span>类型管理</span>
          </el-menu-item>

          <el-menu-item index="/notice">
            <el-icon><BellFilled /></el-icon>
            <span>通知管理</span>
          </el-menu-item>

          <el-menu-item index="/member">
            <el-icon><UserFilled /></el-icon>
            <span>会员管理</span>
          </el-menu-item>

          <el-menu-item index="/boxGameType">
            <el-icon><UserFilled /></el-icon>
            <span>盒子游戏类型</span>
          </el-menu-item>

          <el-menu-item index="/boxGame">
            <el-icon><UserFilled /></el-icon>
            <span>盒子游戏</span>
          </el-menu-item>

        </el-menu>
      </el-aside>
      <el-main style="background-color: aliceblue">
        <router-view @updateProfile="profileUpdated"/>
      </el-main>
    </el-container>

    <div style="position: fixed;bottom: 16px;left: 20px;">
      <el-link type="info" href="https://beian.miit.gov.cn/" style="font-size: large">
        辽ICP备19018776号
      </el-link>
    </div>

  </div>
</template>

<script>
import {
  Document,
  Setting,
  Close,
  User,
  DataAnalysis,
  UserFilled,
  HomeFilled,
  StarFilled,
  Opportunity,
  Connection,
  Film,
    Edit
} from '@element-plus/icons-vue'
import {ElMessageBox} from 'element-plus'
import {loginUser} from "@/api/user";

export default {
  name: 'HelloWorld',
  components: {
    Document,
    Setting,
    // eslint-disable-next-line vue/no-unused-components
    Close,
    // eslint-disable-next-line vue/no-unused-components
    User,
    DataAnalysis,
    UserFilled,
    HomeFilled,
    StarFilled,
    Opportunity
  },
  data() {
    return {
      device: 'pc',
      nn: '',
      nickname: '',
      username: '',
      activeIndex: '/user'
    }
  },
  created() {
    let d = window.localStorage.getItem("p")
    if (d == null) {
      d = 'pc'
      window.localStorage.setItem("p", d)
    }
    this.device = d
    loginUser().then(resp => {
      let data = resp.data.data
      if (data.nickname != null && data.nickname !== '') {
        this.nn = data.nickname[0]
        this.nickname = data.nickname
        this.username = data.username
      }
    })
  },

  mounted() {
    console.log(this.$route.path)
    this.activeIndex = this.$route.path
  },
  watch: {
    $route(e) {
      console.log(e.path)
      this.activeIndex = e.path
    }
  },
  props: {
    msg: String
  },
  methods: {

    changeDevice() {
      console.log(this.device)
      window.localStorage.setItem("p", this.device)
      this.$router.go(0)
    },

    profileUpdated() {
      loginUser().then(resp => {
        let data = resp.data.data
        if (data.nickname != null && data.nickname !== '') {
          this.nn = data.nickname[0]
          this.nickname = data.nickname
          this.username = data.username
        }
      })
    },
    handleCommand(command) {
      // eslint-disable-next-line no-empty
      if (command === '1') {
        this.$router.push('/profile')
      } else if (command === '2') {
        ElMessageBox.confirm('确认退出登录', '确认', {
          autofocus: false,
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
            .then(() => {
              window.localStorage.removeItem('token')
              this.$router.replace('/login')
            })
            .catch(() => {

            })
      }

    },
    openHelp() {
      ElMessageBox(
          {
            title: '提示',
            message: '😃 文档中心规划中，如有疑问，请联系 研发部-李健成'
          }
      )

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.left-menu {
  height: 100%;
  padding-top: 2%;
}

.header {
  width: 100%;
  height: 64px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  z-index: 10;
  background-color: white;
  position: relative;
  display: flex;
  line-height: 64px;
}

h1 {
  margin: 0;
}

.hello {
  margin: 0;
  padding: 0;
  height: 100%;
}

.logo {
  /*height: 100%;*/
  width: 100%;
  margin: 0 30px;
  font-weight: bold;
  font-size: large;
}

.el-aside::-webkit-scrollbar {

  display: none;

}

</style>
