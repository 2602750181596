import request from "@/util/request";

export function pageMember(param) {
    return request({
        url: '/member/page',
        method: 'get',
        params: param
    })
}

export function getMemberById(param) {
    return request({
        url: '/member/getById',
        method: 'get',
        params: {
            id: param
        }
    })
}

export function saveOrUpdateMember(data) {
    return request({
        url: '/member/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function deleteMember(id) {
    return request({
        url: '/member/deleteMember',
        method: 'delete',
        params: {
            memberId: id
        }
    })
}

export function uploadMemberAvatar(data) {
    return request({
        url: '/member/uploadMemberAvatar',
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}

export function listGameByMemberId(memberId) {
    return request({
        url: '/memberGame/listByMemberId',
        method: 'get',
        params: {
            memberId: memberId
        }
    })
}

export function setMemberGame(data) {
    return request({
        url: '/memberGame/setMemberGame',
        method: 'post',
        data: data
    })
}
