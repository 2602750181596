<template>
  <div class="login">
    <div class="login-form">
      <el-card style="height: 88%;padding: 0 15% 0 13%;border-radius: 8px">
        <div style="text-align: center">
          <h2 style="user-select: none">游 戏 后 台</h2>
          <el-form
              label-position="right"
              label-width="60px"
              :model="loginForm"
              style="max-width: 460px;text-align: center"
          >
            <el-form-item label="用户名">
              <el-input v-model="loginForm.username" autofocus="true" prefix-icon="User" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="loginForm.password" type="password" prefix-icon="Lock" show-password @keyup.enter="login" />
            </el-form-item>

<!--/*            <el-form-item style="width: 100%">*/-->
            <div style="display: flex;justify-content: space-between;height: 40px;line-height: 40px;width: 80%;margin: 0 auto">
              <el-checkbox v-model="rememberMe" label="记住我" size="large" />
              <el-link style="margin-right: 0;user-select: none;" type="primary" disabled :underline="false" size="large" @click="forget">忘记密码</el-link>
            </div>

<!--            </el-form-item>-->
            <div>
              <el-button  size="large" round style="width: 50%;margin-top: 3%;font-size: large;font-weight: bold;" @click="login">登 录</el-button>
            </div>


          </el-form>
        </div>
      </el-card>
    </div>

    <div style="position: fixed;bottom: 16px">
      <el-link type="info" href="https://beian.miit.gov.cn/" style="font-size: large">
        辽ICP备19018776号
      </el-link>
    </div>
  </div>
</template>

<script>
import {login} from "@/api/user";
import {ElMessage} from "element-plus";
import {Search} from "@element-plus/icons-vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  components: {
    Search
  },
  data() {
    return {
      rememberMe: false,
      loginForm: {
        username: '',
        password: ''
      }
    }
  },
  created() {
    let token = window.localStorage.getItem("token")
    if (token != null && token !== '') {
      this.$router.replace('/')
      return;
    }
    let un = window.localStorage.getItem('un')
    if (un != null && un !== '') {
      this.rememberMe = true
      this.loginForm.username = un
    }
  },
  methods: {
    login() {

      if(this.loginForm.username == null || this.loginForm.username == '' ||this.loginForm.password == null || this.loginForm.password == ''){
        ElMessage.error('用户名或密码不能为空');
        return;
      }

      login(this.loginForm).then(resp => {
        let data = resp.data.data;
        console.log('登录结果: ', data)
        window.localStorage.setItem("token", data.token)
        if (this.rememberMe) {
          window.localStorage.setItem('un', this.loginForm.username)
        } else {
          window.localStorage.removeItem('un')
        }
        this.$router.replace("/")
      })
    }
  }
}
</script>

<style scoped>

.el-button {
  --el-button-border-color: #b9f21d;
  --el-button-text-color: #000;
  --el-button-bg-color: #b9f21d;
  --el-button-divide-border-color: rgba(255, 255, 255, 0.5);
  --el-button-hover-text-color: #555;
  --el-button-hover-border-color: #c4f712;
  --el-button-hover-bg-color: #c6f914;
  --el-button-active-text-color: var(--el-button-hover-text-color);
  --el-button-active-border-color: #c4f712;
  --el-button-active-bg-color: #c4f712;

}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  background-image: url("@/assets/login-back.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  flex-direction: column;
}

@media screen and (min-width: 1200px){
  .login-form {
    /*justify-content: center;*/
    /*align-items: center;*/
    margin-top: 0px;
    height: 40%;
    width: 30%;
  }
}

@media screen and (max-width: 1199px){
  .login-form {
    /*justify-content: center;*/
    /*align-items: center;*/
    align-self: center;
    height: 50%;
    width: 90%;
  }
}

</style>
