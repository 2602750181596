<template>
  <div>
    <el-row>
      <el-col :span="18">
        <el-card>
          <template #header>
            <div class="card-header" style="display: flex;justify-content: space-between">
              <span>游戏列表</span>
              <span style="width: 25%;display: flex;justify-content: right">
                <el-input v-model="queryParam.gameName" clearable style="width: 65%" @keyup.enter="pageGame" placeholder="游戏名称" />
                <el-button style="margin-left: 10px;" @click="pageGame">搜索</el-button>
              </span>
            </div>
          </template>
          <div>
            <div>
              <el-button type="primary" icon="Plus" @click="handleAddGame">新增</el-button>
            </div>
            <el-table :data="games" style="width: 100%">
              <el-table-column prop="gameCode" label="序号" width="120" />
              <el-table-column prop="gameName" label="游戏名称" width="240" />
              <el-table-column label="游戏类型" width="120">
                <template #default="scope">
                  {{gameTypeOptions.filter(option => option.value === scope.row.gameType)[0].label }}
                </template>
              </el-table-column>
              <el-table-column label="游戏大小" width="150">
                <template #default="scope">
                  {{scope.row.gameSize + ' GB'}}
                </template>
              </el-table-column>


              <el-table-column prop="imgUrl" label="图片" width="180">
                <template #default="scope">
                  <el-image style="width: 80px; height: 80px;" :src="scope.row.imgUrl" fit="fill" />
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button
                      type="primary"
                      :loading="scope.row.deleteLoading"
                      @click="handleEdit(scope.$index, scope.row)"
                      icon="Edit"
                      text
                  >
                    编辑
                  </el-button>
                  <el-button
                      type="danger"
                      :loading="scope.row.deleteLoading"
                      @click="handleDelete(scope.$index, scope.row)"
                      icon="Delete"
                      text
                  >
                    删除
                  </el-button>

                  <el-dropdown size="small" @command="(command) => handleCommand(command, scope.row)">
                <span class="el-dropdown-link">
                  <el-button
                      type="info"
                      :loading="scope.row.deleteLoading"
                      icon="MoreFilled"
                      text
                  >
                    更多
                  </el-button>
                </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="1" icon="PictureFilled">封面图片</el-dropdown-item>
                        <el-dropdown-item command="2" icon="PictureFilled">详情图片</el-dropdown-item>
                        <el-dropdown-item command="3" icon="Document">详情内容</el-dropdown-item>
                        <el-dropdown-item command="4" icon="PictureFilled">推荐图片</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>

                  </el-dropdown>

                </template>
              </el-table-column>
            </el-table>
            <div style="width: 100%;display: flex;justify-content: right;margin-top: 5px">
              <el-pagination background layout="prev, pager, next, jumper" :page-count="pageCount" :current-page="page" @current-change="changePage" />
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>



    <!-- 游戏信息弹框 -->
    <el-dialog
        v-model="openGame"
        :title="title"
        width="30%"
        @close="closeGame"
        align-center
    >

      <el-form :model="game" label-width="120px">

        <el-form-item label="游戏序号">
          <el-input v-model="game.gameCode" style="width: 80%" />
        </el-form-item>

        <el-form-item label="游戏名称">
          <el-input v-model="game.gameName" style="width: 80%" />
        </el-form-item>

        <el-form-item label="游戏大小">
          <el-input v-model="game.gameSize" style="width: 50%" >
            <template #append>GB</template>
          </el-input>
        </el-form-item>

        <el-form-item label="是否即插即玩">
          <el-select v-model="game.gameType" placeholder="请选择游戏类型">
            <el-option
                v-for="item in gameTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="游戏类型">
          <el-select
              v-model="game.typeIdList"
              multiple
              collapse-tags
              placeholder="请选择游戏类型"
              style="width: 240px"
          >
            <el-option
                v-for="item in gameTypeList"
                :key="item.id"
                :label="item.typeName"
                :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="推荐">
          <el-switch v-model="game.isRecommend" />
        </el-form-item>

        <el-form-item label="百度网盘链接">
          <el-input v-model="game.baiduNetDiskUrl" style="width: 100%" />
        </el-form-item>

        <el-form-item label="其他网盘链接">
          <el-input v-model="game.otherNetDiskUrl" style="width: 80%" />
        </el-form-item>

        <el-form-item label="操作系统配置">
          <el-input v-model="game.osRequire" style="width: 80%" />
        </el-form-item>

        <el-form-item label="处理器配置">
          <el-input v-model="game.cpuRequire" style="width: 80%" />
        </el-form-item>

        <el-form-item label="内存配置">
          <el-input v-model="game.ramRequire" style="width: 80%" />
        </el-form-item>

        <el-form-item label="显卡配置">
          <el-input v-model="game.graphicsRequire" style="width: 80%" />
        </el-form-item>

        <el-form-item label="存储空间配置">
          <el-input v-model="game.diskRequire" style="width: 80%" />
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openGame = false">取消</el-button>
          <el-button type="primary" @click="submitGame">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 游戏图片弹框 -->
    <el-dialog
        v-model="openGameImg"
        :title="title"
        width="30%"
        @close="closeGame"
    >

      <el-form :model="game" label-width="120px">

        <el-form-item label="上传图片">
          <el-upload
              ref="uploadGameImgRef"
              drag
              :on-exceed="handleExceed"
              :on-success="handleGameImgSuccess"
              :on-change="handleGameImgChange"
              :auto-upload="false"
              accept=".jpg,.jpeg,.png"
              :file-list="gameImgFileList"
              :limit="1"
              action="/website-api/game/uploadGameImg"
              :headers="headers"
              style="width: 50%"
              :list-type="'picture'"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              拖拽或 <em>上传</em> 文件
            </div>
            <template #tip>
              <div class="el-upload__tip">
                建议尺寸: 250 x 350
              </div>
            </template>
          </el-upload>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openGameImg = false">取消</el-button>
          <el-button type="primary" @click="submitGameImg">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 详情图片弹框 -->
    <el-dialog
        v-model="openDetailImg"
        :title="title"
        width="40%"
        @close="closeGame"
    >

      <el-form :model="game" label-width="120px">

        <el-form-item label="当前图片" v-if="currentDetailImgList.length > 0">
          <el-image v-for="(item, index) in currentDetailImgList" :key="index"
                    style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;margin-top: 10px;box-shadow: 0 2px 4px rgba(0, 0, 0, .52), 0 0 6px rgba(0, 0, 0, .14);border-radius: 10px;"
                    :src="item"
                    :preview-src-list="[item]"
                    fit="fill"
                    :lazy="true"
          >
            <template v-slot:placeholder>
              <div class="image-slot" style="font-size: small">加载中</div>
            </template>

            <template #error>
              <div class="image-slot">
                <el-icon><icon-picture /></el-icon>
              </div>
            </template>
          </el-image>

        </el-form-item>

        <el-form-item label="上传图片">
          <el-upload
              ref="uploadGameDetailImgRef"
              drag
              :on-exceed="handleExceed"
              :on-success="handleGameImgSuccess"
              :on-change="handleGameDetailImgChange"
              :auto-upload="false"
              accept=".jpg,.jpeg,.png"
              :file-list="gameImgFileList"
              :data="game"
              :limit="4"
              multiple
              action="/website-api/game/uploadGameDetailImg"
              :headers="headers"
              style="width: 50%"
              :list-type="'picture'"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              拖拽或 <em>上传</em> 文件
            </div>
            <template #tip>
              <div class="el-upload__tip">
                建议尺寸: 250 x 350
              </div>
            </template>
          </el-upload>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openDetailImg = false">取消</el-button>
          <el-button type="primary" @click="submitGameDetailImg">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 游戏详情弹框 -->
    <el-dialog
        v-model="openDetailText"
        :title="title"
        width="50%"
        style="height: 80%"
        @close="closeGame"
        align-center
    >

      <el-form :model="game" label-width="120px">

        <el-form-item label="游戏序号">
          <el-input v-model="game.gameCode" disabled style="width: 80%" />
        </el-form-item>

        <el-form-item label="游戏名称">
          <el-input v-model="game.gameName" disabled style="width: 80%" />
        </el-form-item>

        <el-form-item label="详情内容">
<!--          <el-input-->
<!--              v-model="game.detailText"-->
<!--              :rows="5"-->
<!--              type="textarea"-->
<!--              placeholder="请输入游戏介绍"-->
<!--          />-->
          <div style="border: 1px solid #ccc;height: 380px;" v-if="openDetailText">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 280px; overflow-y: hidden;"
                v-model="game.detailText"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openDetailText = false">取消</el-button>
          <el-button type="primary" @click="submitGame">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 推荐图片弹框 -->
    <el-dialog
        v-model="openRecommend"
        :title="title"
        width="30%"
        @close="closeGame"
    >

      <el-form :model="game" label-width="120px">

        <el-form-item label="上传图片">
          <el-upload
              ref="uploadRecommendImgRef"
              drag
              :on-exceed="handleExceed"
              :on-success="handleRecommendImgSuccess"
              :on-change="handleGameImgChange"
              :auto-upload="false"
              accept=".jpg,.jpeg,.png"
              :file-list="gameImgFileList"
              :limit="1"
              action="/website-api/game/uploadRecommendImg"
              :headers="headers"
              style="width: 50%"
              :list-type="'picture'"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              拖拽或 <em>上传</em> 文件
            </div>
            <template #tip>
              <div class="el-upload__tip">
                建议尺寸:
              </div>
            </template>
          </el-upload>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openRecommend = false">取消</el-button>
          <el-button type="primary" @click="submitRecommendImg">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {uploadTopGameImg, pageGame, deleteGame, getById, saveOrUpdate, uploadGameDetailImg, uploadGameCoverImg, uploadGameRecommendImg} from "@/api/boxGame";
import {listGameType} from "@/api/boxGameType";
import {Editor, Toolbar} from "@wangeditor/editor-for-vue";

import '@wangeditor/editor/dist/css/style.css'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "BoxGame",

  components: {Editor, Toolbar},

  data() {
    return {
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {}
      },
      mode: 'default', // or 'simple'
      queryParam: {},
      gameTypeIdOption: [
        {value: 1, label: '体验类'},
        {value: 2, label: '互动类'}
      ],
      games: [],
      gameImgFileList: [],
      currentDetailImgList: [],
      gameTypeList: [],
      game: {},
      form: {},
      title: '',
      openGame: false,
      openGameImg: false,
      openGameVideo: false,
      openDetailImg: false,
      openDetailText: false,
      videoForm: {},
      topGameImgForm: {},
      videoFileList: [],
      videos: {},
      headers: {},
      videoLoading: false,
      topGameImgFileList: [],
      topGameImgs: {},
      topGameImgLoading: false,
      page: 1,
      pageSize: 5,
      pageCount: 0,
      gameTypeOptions: [
        {value: '1', label: '即插即玩'},
        {value: '2', label: '需安装'}
      ],
      openRecommend: false,
    }
  },

  mounted() {
    setTimeout(() => {
      this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    }, 1500)
  },

  created() {

    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: '/website-api/game/richTextUpload',
      fieldName: 'file',
      onSuccess(file, res) {
        console.log(`${file.name} 上传成功`, res)
      },
      onFailed(file, res) {
        console.log(`${file.name} 上传失败`, res)
      },
      onError(file, err, res) {               // JS 语法
        console.log(`${file.name} 上传出错`, err, res)
      },
    }

    // this.get()
    const token = window.localStorage.getItem('token')
    this.headers = {
      Authorization: token
    }

    pageGame({page: this.page, pageSize: this.pageSize, gameName: this.queryParam.gameName}).then(resp => {
      this.pageCount = resp.data.data.pages
      this.games = resp.data.data.records
    })

  },

  methods: {

    getRichText() {
      const editor = this.editor // 获取 editor 实例
      if (editor == null) return
      let rich = editor.getHtml()
      console.log(rich)
    },

    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },

    handleGameDetailImgChange(file, files) {
      this.gameImgFileList = files
    },
    handleGameImgChange(file, files) {
      this.gameImgFileList = files
    },

    handleCommand(command, row) {
      getById(row.id).then(resp => {
        this.game = resp.data.data
        const token = window.localStorage.getItem('token')
        const p = window.localStorage.getItem("p")
        this.headers = {
          Authorization: token,
          p: p
        }
        if (command === '1') {
          this.title = '封面图片'
          this.openGameImg = true
        } else if (command === '2') {

          if (this.game.detailImg != null && this.game.detailImg.length > 0) {
            this.currentDetailImgList = this.game.detailImg.split(',')
          } else {
            this.currentDetailImgList = []
          }
          this.title = '详情图片'
          this.openDetailImg = true
        } else if (command === '3') {
          this.title = '详情内容'
          this.openDetailText = true
        } else if (command === '4') {
          this.title = '推荐图片'
          this.openRecommend = true
        }

      })


    },

    handleDelete(index, row) {
      deleteGame(row.id).then(resp => {
        this.pageGame()
      })
    },

    handleEdit(index, row) {
      this.title = '修改游戏'
      getById(row.id).then(resp => {
        this.game = resp.data.data
      })
      listGameType().then(resp => {
        this.gameTypeList = resp.data.data
        this.openGame = true;
      })

    },

    pageGame() {
      pageGame({page: this.page, pageSize: this.pageSize, gameName: this.queryParam.gameName}).then(resp => {
        this.pageCount = resp.data.data.pages
        this.games = resp.data.data.records
      })
    },

    changePage(newPage) {
      this.page = newPage
      this.pageGame()
    },

    submitGameImg() {
      let fd = new FormData()
      console.log('lalala ', this.gameImgFileList)
      if (this.gameImgFileList == null || this.gameImgFileList.length === 0) {
        return
      }
      // this.gameImgFileList.forEach(file => {
      //   fd.append('fileList', file.raw)
      // })
      this.gameImgFileList.forEach(file => {
        fd.append('file', file.raw)
      })
      fd.append('gameId', this.game.id)
      uploadGameCoverImg(fd).then(resp => {
        this.$refs.uploadGameImgRef.clearFiles()
        this.pageGame()
        this.openGameImg = false
      })
      // this.$refs.uploadGameImgRef.submit()
    },

    submitRecommendImg() {
      let fd = new FormData()
      if (this.gameImgFileList == null || this.gameImgFileList.length === 0) {
        return
      }
      // this.gameImgFileList.forEach(file => {
      //   fd.append('fileList', file.raw)
      // })
      this.gameImgFileList.forEach(file => {
        fd.append('file', file.raw)
      })
      fd.append('gameId', this.game.id)
      uploadGameRecommendImg(fd).then(resp => {
        this.$refs.uploadRecommendImgRef.clearFiles()
        this.pageGame()
        this.openRecommend = false
      })
    },

    submitGameDetailImg() {
      let fd = new FormData()
      this.gameImgFileList.forEach(file => {
        fd.append('fileList', file.raw)
      })
      fd.append('id', this.game.id)
      uploadGameDetailImg(fd).then(resp => {
        this.$refs.uploadGameDetailImgRef.clearFiles()
        this.openDetailImg = false
      })

      // this.$refs.uploadGameDetailImgRef.submit()
    },

    submitGame() {
      saveOrUpdate(this.game).then(resp => {
        this.pageGame()
        this.openGame = false
        this.openDetailText = false
        this.openDetailImg = false
        ElMessage({
          type: 'success',
          message: this.game.id ? '修改成功' : '添加成功'
        })
        this.game = {}
      })
    },

    closeGame() {
      // this.$refs.uploadGameImgRef.clearFiles()
      this.game = {}
    },

    handleGameImgSuccess() {
      this.pageGame()
      this.openGameImg = false
      this.openRecommend = false
      this.$refs.uploadGameImgRef.clearFiles()
    },

    handleRecommendImgSuccess() {
      this.pageGame()
      this.openRecommend = false
      this.$refs.uploadRecommendImgRef.clearFiles()
    },

    handleAddGame() {
      this.title = '新增游戏'
      listGameType().then(resp => {
        this.gameTypeList = resp.data.data
        this.openGame = true;
      })
    },

    listGame() {

    },

    get() {
      // getTopGame().then(resp => {
      //   this.videoForm = resp.data.data
      //   this.topGameImgForm = resp.data.data
      //   this.topGameImgForm.imgUrl = this.topGameImgForm.imgUrl !== '' ? this.topGameImgForm.imgUrl.split(',') : []
      // })
    },

    handleExceed() {
      ElMessage({
        type: 'error',
        message: '超出上传数量限制',
        duration: "1000"
      })
    },

    confirmVideo() {
      this.videoLoading = true
      this.$refs.uploadVideoRef.submit()
    },

    handleVideoSuccess() {
      this.$refs.uploadVideoRef.clearFiles()
      ElMessage({
        type: 'success',
        message: '上传成功'
      })
      this.videoLoading = false
      this.openGameVideo = false
    },

    handleTopGameImgSuccess() {
      this.$refs.uploadTopGameImgRef.clearFiles()
      // this.get()
    },

    handleTopGameImgChange(file, files) {
      this.topGameImgFileList = files
    },

    submitTopGameImg() {
      let fd = new FormData()
      if (this.topGameImgFileList.length === 0) {
        return
      }
      this.topGameImgLoading = true
      this.topGameImgFileList.forEach(file => {
        fd.append('fileList', file.raw)
      })
      uploadTopGameImg(fd).then(resp => {
        // this.get()
        this.$refs.uploadTopGameImgRef.clearFiles()
        this.topGameImgLoading = false
      })
    },

  },

  beforeUnmount() {
    const editor = this.editor
    if (editor == null) {
      return
    }
    editor.destroy()
  }
}
</script>

<style scoped>

</style>
