<template>
<div>
  <el-card>
    <template #header>
      <div class="card-header" style="display: flex;justify-content: space-between">
        <span>会员管理</span>
        <span style="width: 25%;display: flex;justify-content: right">
                <el-input v-model="queryParam.nickname" clearable style="width: 65%" @keyup.enter="pageMember" placeholder="会员昵称" />
                <el-button style="margin-left: 10px;" @click="pageMember">搜索</el-button>
              </span>
      </div>
    </template>
    <div>
      <div>
        <el-button type="primary" icon="Plus" @click="handleAddMember">新增</el-button>
      </div>
      <el-table :data="members" style="width: 100%">
        <el-table-column prop="avatarUrl" label="头像" width="180">
          <template #default="scope">
            <el-image v-show="scope.row.avatarUrl" style="width: 80px; height: 80px;" :src="scope.row.avatarUrl" fit="fill" />
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="会员昵称" width="200" />
        <el-table-column prop="memberName" label="手机号" width="240" />


        <el-table-column prop="createTime" label="创建时间" width="200">
        </el-table-column>

        <el-table-column label="操作">
          <template #default="scope">
            <el-button
                type="primary"
                :loading="scope.row.deleteLoading"
                @click="handleEdit(scope.$index, scope.row)"
                icon="Edit"
                text
            >
              编辑
            </el-button>
            <el-button
                type="danger"
                :loading="scope.row.deleteLoading"
                @click="handleDelete(scope.$index, scope.row)"
                icon="Delete"
                text
            >
              删除
            </el-button>

            <el-dropdown size="small" @command="(command) => handleCommand(command, scope.row)">
                <span class="el-dropdown-link">
                  <el-button
                      type="info"
                      :loading="scope.row.deleteLoading"
                      icon="MoreFilled"
                      text
                  >
                    更多
                  </el-button>
                </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="1" icon="PictureFilled">头像</el-dropdown-item>
                  <el-dropdown-item command="2" icon="PictureFilled">发放游戏</el-dropdown-item>
                </el-dropdown-menu>
              </template>

            </el-dropdown>

          </template>
        </el-table-column>
      </el-table>
      <div style="width: 100%;display: flex;justify-content: right;margin-top: 5px">
        <el-pagination background layout="prev, pager, next, jumper" :page-count="pageCount" :current-page="page" @current-change="changePage" />
      </div>
    </div>
  </el-card>

  <!-- 游戏信息弹框 -->
  <el-dialog
      v-model="openMember"
      :title="title"
      width="30%"
      @close="closeMember"
  >

    <el-form :model="member" label-width="120px">

      <el-form-item label="会员名称">
        <el-input v-model="member.memberName" style="width: 80%" />
      </el-form-item>

      <el-form-item label="昵称">
        <el-input v-model="member.nickname" style="width: 80%" />
      </el-form-item>

    </el-form>

    <template #footer>
        <span class="dialog-footer">
          <el-button @click="openGame = false">取消</el-button>
          <el-button type="primary" @click="submitMember">确定</el-button>
        </span>
    </template>
  </el-dialog>

  <!-- 游戏图片弹框 -->
  <el-dialog
      v-model="openMemberAvatar"
      :title="title"
      width="30%"
      @close="closeMember"
  >

    <el-form :model="member" label-width="120px">

      <el-form-item label="上传图片">
        <el-upload
            ref="uploadMemberAvatarRef"
            drag
            :on-exceed="handleExceed"
            :on-success="handleMemberAvatarSuccess"
            :on-change="handleGameImgChange"
            :auto-upload="false"
            accept=".jpg,.jpeg,.png"
            :file-list="gameImgFileList"
            :limit="1"
            action="/website-api/member/uploadMemberAvatar"
            :headers="headers"
            style="width: 50%"
            :list-type="'picture'"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            拖拽或 <em>上传</em> 文件
          </div>
          <template #tip>
            <div class="el-upload__tip">
              建议尺寸: 250 x 350
            </div>
          </template>
        </el-upload>
      </el-form-item>

    </el-form>

    <template #footer>
        <span class="dialog-footer">
          <el-button @click="openMemberAvatar = false">取消</el-button>
          <el-button type="primary" @click="submitGameImg">确定</el-button>
        </span>
    </template>
  </el-dialog>


  <el-dialog
      v-model="openMemberGame"
      :title="title"

      @close="closeMember"
  >

    <el-form :model="member" label-width="180px">

      <el-form-item label="昵称">
        <strong>{{member.nickname}}</strong>
      </el-form-item>

    </el-form>

    <div style="width: 90%;margin: 0 auto;height: 300px;">
      <div style="display: flex;align-items: center;height: 60px">
        <el-select
            v-model="selectedGameId"
            placeholder="发放游戏名"
            size="large"
            style="width: 60%;margin-right: 16px"
            clearable
            filterable
        >
          <el-option
              v-for="item in gameOptions"
              :key="item.id"
              :label="item.gameName"
              :value="item.id"
          />
        </el-select>
        <el-button type="primary" :disabled="selectedGameId == null" @click="addMemberGame">添加</el-button>
      </div>

      <el-table :data="gameList" stripe style="border: solid 1px lightgray" height="250" >
        <el-table-column prop="gameName" label="游戏名称" width="500" />

        <el-table-column label="操作">
          <template #default="scope">

            <el-button
                type="danger"
                :loading="scope.row.deleteLoading"
                @click="handleDeleteMemberGame(scope.$index, scope.row)"
                icon="Delete"
                text
            >
              删除
            </el-button>



          </template>
        </el-table-column>
      </el-table>
    </div>



    <template #footer>
        <span class="dialog-footer">
          <el-button @click="openMemberGame = false">取消</el-button>
          <el-button type="primary" @click="submitMemberGame">确定</el-button>
        </span>
    </template>
  </el-dialog>

</div>
</template>

<script>
import {
  pageMember, getMemberById, saveOrUpdateMember, deleteMember,
  uploadMemberAvatar, listGameByMemberId, setMemberGame} from "@/api/member";
import {ElMessage} from "element-plus";
import {listGame} from "@/api/boxGame";

export default {
  name: "Member",
  data() {
    return {
      selectedGameId: null,
      queryParam: {},
      gameTypeIdOption: [
        {value: 1, label: '体验类'},
        {value: 2, label: '互动类'}
      ],
      gameOptions: [],
      gameList: [],
      members: [],
      gameImgFileList: [],
      currentDetailImgList: [],
      gameTypeList: [],
      member: {},
      form: {},
      title: '',
      openMember: false,
      openGameImg: false,
      openMemberAvatar: false,
      openMemberGame: false,
      videoForm: {},
      topGameImgForm: {},
      videoFileList: [],
      videos: {},
      headers: {},
      videoLoading: false,
      topGameImgFileList: [],
      topGameImgs: {},
      topGameImgLoading: false,
      page: 1,
      pageSize: 5,
      pageCount: 0,
      gameTypeOptions: [
        {value: '1', label: '即插即玩'},
        {value: '2', label: '需安装'}
      ]
    }
  },
  methods: {

    addMemberGame() {
      if (!this.gameList.some(g => g.id === this.selectedGameId)) {
        let gn = this.gameOptions.filter(g => g.id == this.selectedGameId)[0].gameName
        this.gameList.push({
          memberId: this.member.id,
          gameId: this.selectedGameId,
          gameName: gn
        })
      }
      this.selectedGameId = null
    },

    submitMemberGame() {
      setMemberGame(this.gameList).then(resp => {
        ElMessage({
          type: 'success',
          message: '添加成功'
        })
        this.openMemberGame = false
        this.selectedGameId = null
        this.gameList = []
      })
    },

    handleGameImgChange(file, files) {
      this.gameImgFileList = files
    },

    pageMember() {
      pageMember({page: this.page, pageSize: this.pageSize, nickname: this.queryParam.nickname}).then(resp => {
        this.pageCount = resp.data.data.pages
        this.members = resp.data.data.records
      })
    },
    handleEdit(index, row) {
      this.title = '修改会员'
      getMemberById(row.id).then(resp => {
        this.member = resp.data.data
        this.openMember = true;
      })
    },

    changePage(newPage) {
      this.page = newPage
      this.pageMember()
    },

    submitMember() {
      saveOrUpdateMember(this.member).then(resp => {
        this.pageMember()
        this.openMember = false
        ElMessage({
          type: 'success',
          message: this.member.id ? '修改成功' : '添加成功'
        })
        this.member = {}
      })
    },

    handleAddMember() {
      this.title = '新增游戏'
      this.member = {}
      this.openMember = true
    },

    handleDelete(index, row) {
      deleteMember(row.id).then(resp => {
        this.pageMember()
      })
    },
    handleCommand(command, row) {
      getMemberById(row.id).then(resp => {
        this.member = resp.data.data
        if (command == '1') {
          this.title = '头像'
          this.openMemberAvatar = true
        } else if (command == '2') {
          // 发放游戏
          listGameByMemberId(row.id).then(resp => {
            this.gameList = resp.data.data
            this.title = '发放游戏'
            this.openMemberGame = true
          })
        }
      })
    },
    closeMember() {
      this.member = {}
    },

    handleExceed() {
      ElMessage({
        type: 'error',
        message: '超出上传数量限制',
        duration: "1000"
      })
    },

    handleMemberAvatarSuccess() {
      this.pageMember()
      this.openMemberAvatar = false
      this.$refs.uploadMemberAvatarRef.clearFiles()
    },

    submitGameImg() {
      let fd = new FormData()

      if (this.gameImgFileList == null || this.gameImgFileList.length === 0) {
        return
      }
      // this.gameImgFileList.forEach(file => {
      //   fd.append('fileList', file.raw)
      // })
      this.gameImgFileList.forEach(file => {
        fd.append('file', file.raw)
      })
      fd.append('memberId', this.member.id)
      uploadMemberAvatar(fd).then(resp => {
        this.$refs.uploadMemberAvatarRef.clearFiles()
        this.pageMember()
        this.openMemberAvatar = false
      })
      // this.$refs.uploadGameImgRef.submit()
    },

    handleDeleteMemberGame(index, row) {

        this.gameList.splice(index, 1)

    },

  },
  mounted() {

  },
  created() {
    pageMember({page: this.page, pageSize: this.pageSize, nickname: this.queryParam.nickname}).then(resp => {
      this.pageCount = resp.data.data.pages
      this.members = resp.data.data.records
    })
    listGame().then(resp => {
      this.gameOptions = resp.data.data
    })
  },

}
</script>

<style scoped>

</style>
