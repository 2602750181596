import request from "@/util/request";

export function getTopGame() {
    return request({
        url: '/game/getTopGame',
        method: 'get'
    })
}

export function uploadTopGameImg(data) {
    return request({
        url: '/game/uploadTopGameImg',
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}

export function pageGame(param) {
    return request({
        url: '/game/page',
        method: 'get',
        params: param
    })
}

export function deleteGame(gameId) {
    return request({
        url: '/game/deleteGame',
        method: 'delete',
        params: {
            gameId: gameId
        }
    })
}

export function getById(id) {
    return request({
        url: '/game/getById',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function saveOrUpdate(data) {
    return request({
        url: '/game/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function uploadGameDetailImg(data) {
    return request({
        url: '/game/uploadGameDetailImg',
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}

export function uploadGameCoverImg(data) {
    return request({
        url: '/game/uploadGameImg',
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}

export function uploadGameRecommendImg(data) {
    return request({
        url: '/game/uploadRecommendImg',
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}

export function listRecommendGame() {
    return request({
        url: '/game/listRecommendGame',
        method: 'get'
    })
}

export function listGame() {
    return request({
        url: '/game/list',
        method: 'get',
    })
}
