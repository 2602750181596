<template>
  <div>
    <el-card style="width: 55%;">
      <div>
        <div style="width: 100%;display: flex;justify-content: space-between">
          <span>
            <el-icon><Grid /></el-icon>
            <strong>&nbsp;游戏类型</strong>
          </span>
        </div>

        <div style="margin-top: 30px;">
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </div>

        <el-table stripe :data="gameTypeList" style="width: 100%;min-height: 550px;margin-top: 2%;" empty-text="暂无数据">
          <el-table-column prop="typeName" label="类型名称" width="300" />
          <el-table-column prop="createTime" label="创建时间"  />
          <el-table-column label="操作" >

            <template #default="scope">

              <el-button
                  type="primary"
                  :loading="scope.row.deleteLoading"
                  @click="handleEdit(scope.row)"
                  icon="Edit"
              >
                修改
              </el-button>

              <el-button
                  type="danger"
                  :loading="scope.row.deleteLoading"
                  @click="handleDelete(scope.row)"
                  icon="Delete"
              >
                删除
              </el-button>

            </template>

          </el-table-column>
        </el-table>

      </div>
    </el-card>

    <el-dialog
        v-model="openGameType"
        :title="title"
        width="30%"
    >

      <el-form :model="form" label-width="120px">

        <el-form-item label="类型名称">
          <el-input v-model="form.typeName" style="width: 80%" />
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openGameType = false">取消</el-button>
          <el-button type="primary" @click="submitGameType">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import {listGameType, saveOrUpdateGameType, deleteGameType} from "@/api/boxGameType";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name: "BoxGameType",
  data() {
    return {
      title: '',
      gameTypeList: [],
      form: {},
      openGameType: false
    }
  },
  created() {
    listGameType().then(resp => {
      this.gameTypeList = resp.data.data
    })
  },
  methods: {
    handleAdd() {
      this.title = "新增游戏类型"
      this.openGameType = true
    },
    handleEdit(row) {
      this.title = "修改游戏类型"
      this.form = row
      this.openGameType = true
    },
    handleDelete(row) {
      ElMessageBox.confirm(
          '确认删除 "' + row.typeName + '"',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(() => {
        deleteGameType(row.id).then(resp => {
          listGameType().then(resp => {
            this.gameTypeList = resp.data.data
          })
        })
      }).catch(() => {

      })
    },
    submitGameType() {
      saveOrUpdateGameType(this.form).then(resp => {
        listGameType().then(resp => {
          this.gameTypeList = resp.data.data
          ElMessage({
            type: 'success',
            message: this.form.id ? '修改成功' : '添加成功'
          })
          this.form = {}
          this.openGameType = false
        })

      })
    }
  }
}
</script>

<style scoped>

</style>
